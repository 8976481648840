const shiftOBJ= {
    id:null,
    name:null,
    desc: null,
    from: null,
    to: null,
    interval:null,
    shift_type:null,
    areas:[],
    area_roles:[],
    
 
}
const areaOBJ= {
    id:null,
    name:null,
    location_id: null,
    color:null,
    roles:[],
    
 
}
const roleOBJ= {
    id:null,
    name:null,
    area_id: null,
    
 
}
export const state = {
    shift:{
        ...shiftOBJ
    },
    area:{
        ...areaOBJ,
    },
    role:  {...roleOBJ}
   
    
}

export const actions = {
    getShift({commit},{data}){
        commit('updateShift',data)              
        
    },
    
    resetState({commit}) {
        
        commit('updateShift',shiftOBJ);
        commit('updateArea',areaOBJ);
        commit('updateRole',roleOBJ);
     },
    resetArea({commit}) {
        commit('updateArea',areaOBJ);
     }
    
}

export const mutations= {
    updateShift(state, new_val) {
        state.shift = new_val
    },
    updateArea(state, new_val) {
        state.area = new_val
    },
    updateRole(state, new_val) {
        state.role = new_val
    },
}

export const getters = {
    ShiftGetter: state => state.shift
  };




