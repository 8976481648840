import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import router from './router';

import vco from "v-click-outside";
import i18n from './i18n';

import VueSweetalert2 from 'vue-sweetalert2';
import VueApexCharts from 'vue-apexcharts';

import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import store from '@/state/store';
import axios from 'axios';
import SubmitButton from "./components/general/SubmitButton";
import APILoader from "./components/general/APILoader";

import {firebaseMessaging} from '@/plugins/firebase';
import './registerServiceWorker'
import VCalendar from 'v-calendar';
Vue.use(VCalendar, {
  componentPrefix: 'vc', 
});

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

Vue.prototype.$messaging = firebaseMessaging;

// Vue.config.devtools = true;
import App from './App.vue';

import VueObserveVisibility from 'vue-observe-visibility'
import { ObserveVisibility } from 'vue-observe-visibility'

Vue.use(VueObserveVisibility);
Vue.directive('observe-visibility', ObserveVisibility);
// As a plugin
Vue.config.productionTip = false

Vue.use(VueTelInput);

// import mixins
import GlobalMixin from "./mixins/global";

if (store.getters['auth/loggedIn']) {
  Vue.mixin(GlobalMixin);
}



import '@/assets/scss/app.scss'

Vue.component('VueSlideBar', VueSlideBar)
Vue.component('SubmitButton', SubmitButton)
Vue.component('APILoader', APILoader)
Vue.component('apexchart', VueApexCharts)
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(vco)

Vue.use(VueSweetalert2);

import SawalMixin from "./mixins/swalToasterMixin";
Vue.mixin(SawalMixin);



const Base_Url = process.env.VUE_APP_ROOT_API + 'api';


var user = localStorage.getItem('authUser') == "undefined" ? null : JSON.parse(localStorage.getItem('authUser'));

Vue.prototype.$axios = axios.create({
  baseURL: Base_Url,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  }
});

if(user?.token){
  Vue.prototype.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + user?.token;
 }


new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')


import PortalVue from "portal-vue";
Vue.use(PortalVue);