import {app} from '@/plugins/firebase.js'
import {getMessaging, getToken} from "firebase/messaging";


const messaging = getMessaging(app);

export function requestNotificationPermission() {
   return new Promise((resolve, reject) => {

      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
         alert("This browser does not support desktop notification");
         resolve();
      }

      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
         // If it's okay let's create a notification
         getFcmToken().then((r) => {
            resolve(r);
         }).catch(e => reject(e));
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== "denied") {
         Notification.requestPermission().then(function (permission) {
            if (permission === "granted") {
               getFcmToken().then((r) => {
                  resolve(r);
               }).catch(e => reject(e));
            }
         });
      } else {
         resolve();
      }


   })
}

export function getFcmToken() {
   return new Promise((resolve, reject) => {
      try {
         getToken(messaging, {vapidKey: process.env.VAPID_KEY}).then((currentToken) => {
            if (currentToken) {
               // console.log(currentToken);
               resolve(currentToken)
            } else {
               // Show permission request UI
               reject('No registration token available. Request permission to generate one.')

            }
         }).catch((err) => {
            reject('An error occurred while retrieving token. ', err)
         });

      } catch (e) {
         reject(e)
      }
   })
}

