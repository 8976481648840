import $ from "jquery";
import { Role } from "@/state/helpers";
import moment from "moment";


export default {
    data() {
        return {
            // assets_url:'http://shift-fish.local/storage/',
            assets_url: process.env.VUE_APP_ROOT_API+'storage/',
            // currentUser:this.$store.getters["auth/currentUser"]
        }
    },
    computed: {
        currentUser() {
            return this.$store.getters["auth/currentUser"]
        }
    },


    methods: {
        isInvalid(target) {

            if (target == null || target == 'undefined' ||  target === "" || typeof target === "undefined") {
                return true;
            } else {
                return false;
            }
        },
        getSafe(fn, default_value = "N/A") {
            try {
               return fn();
            } catch (e) {
               return default_value;
            }
         },
        isUndefined(target) {
            if (typeof target === "undefined") {
                return true;
            } else {
                return false;
            }
        },
        getDefault(target, default_value = "N/A") {
            if (!this.isInvalid(target)) {
                return target;
            } else {
                return default_value;
            }
        },
        isSuperAdmin() {
            return this.currentUser.type == Role.SuperAdmin;
        },
        isRestaurantOwner() {
            return this.currentUser.type == Role.RestaurantOwner;
        },
        isTeamMember() {
            return this.currentUser.type == Role.TeamMember;
        },
        isManager() {
            return this.currentUser.type == Role.Manager;
        },

        validateForm(form_id) {

            var validate = $("#" + form_id).validate({
                errorElement: 'span'
            });
            validate.form();
            return validate.errorList.length
        },

        redirectAfterDelay(url = null, delay = 2000) {
            setTimeout(
                () => {
                    if (url) {
                        window.location.replace(url);
                    } else {
                        location.reload();
                    }
                },
                delay
            );
        },
        formattedTime(value) {
            let result =  this.changeDateTimeToLocalFormats(value)
            const format = "h:ma"
            return moment(result).format(format);
        },
        getCurrentDate() {
            const d = new Date();
            return d.toISOString().substring(0, 10);
        },


        getDayName(dateString) {
            var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
            var d = new Date(dateString);
            return days[d.getDay()];
        },

        getDayNumberByName(dayName) {
            let dayNumber;
            switch (dayName) {
                case 'Sunday':
                    dayNumber = 0;
                    break;
                case 'Monday':
                    dayNumber = 1;
                    break;
                case 'Tuesday':
                    dayNumber = 2;
                    break;
                case 'Wednesday':
                    dayNumber = 3;
                    break;
                case 'Thursday':
                    dayNumber = 4;
                    break;
                case 'Friday':
                    dayNumber = 5;
                    break;
                case 'Saturday':
                    dayNumber = 6;
                    break;

            }
            return dayNumber;
        },

        formatDateYMD(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;

            return [year, month, day].join('-');
        },

        formatDateTime(date) {
            const format = "DD-MM-YYYY h:mm A"
            return moment(this.changeDateTimeToLocalFormats(date)).format(format);
        },

        customFormatDate(date,format) {
            if(!format){
                format = "DD MMM YY";
            }
            let result =  this.changeDateTimeToLocalFormats(date);
            return moment(result).format(format);
        },

        DateWithMonthWithoutYear(date) {
            // let result =  this.changeDateTimeToLocalFormats(date);
            // var d = new Date(result);
            const format = "DD-MMM h:mm A"
            var dateformating = moment(date).format(format);
            // var finalDate = dateformating + ' ' + d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            return dateformating;  //01-Sep 4:10 PM
        },
        DateWithMonthName(date , format='DD-MMM-YYYY hh:mm A') {
            // let result =  this.changeDateTimeToLocalFormats(date);
            
            // var d = new Date(result);
            // const format = "DD-MMM-YYYY"
            // var dateformating = moment(date).format(format);
            // var finalDate = dateformating + ' ' + d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            // return result;  //01-Sep-2022 4:10 PM
            
            return moment(date).format(format);
        },
        changeDateTimeToLocalFormats(date) {
            try {
                const UTC_DATE = new Date(date);
                const localeDate = UTC_DATE.toLocaleDateString();
                const localeTime = UTC_DATE.toLocaleTimeString();
          
                const format = "yyyy-MM-DDTHH:mm";
                return moment(`${localeDate} ${localeTime}`, "MM/DD/YYYY hh:mm:ss A").format(format);

             } catch (e) {
                console.log(e)
             }
        },
        getDayname(date) {
            var weekDayName = moment(date).format('dddd');
            return weekDayName
        },

        changeDateTimeToUTCZero(dateTime) {
            const isoDateTime = new Date(dateTime).toISOString();
         
            // const date = isoDateTime.split('T')[0];
            // const time = isoDateTime.split('T')[1].split('.')[0];
         
            // return date + ' ' + time;
            return isoDateTime;
            
         },

        changeDateTimeTimeZone(dateTime,zone) {
            
            let timestamp = null;
            if(!zone){
                timestamp = dateTime;
            }else{
                timestamp = new Date(dateTime).toLocaleString('en-US', { timeZone: zone });
            }
            const format = "DD-MM-YYYY hh:mm A"
            return moment(timestamp, "MM/DD/YYYY hh:mm:ss A").format(format);
         },

        changeFormatTimeCalendarBanner(date)
        {
        let result = moment(date, 'DD-MM-YYYY hh:mm A').format('YYYY-MM-DDTHH:mm:ss');
        return result;

        },


    },
    mounted() {
        $('.fc-scrollgrid-sync-inner').on('mouseenter', function () {
            $(this).find('.hovered-i-tag').remove();
            $(this).append('<i class="hovered-i-tag uil-plus"></i>');
        }).on('mouseleave', function () {
            $(this).find('.hovered-i-tag').remove();
        });
    },

}
