<template>
    <p class="text-center">
        <span><i v-if="loading" class='fa-2x fa-spin fa-spinner fas primaryColor'></i></span>        
    </p>
</template>

<script>

export default {
    props: {
        loading: {default: false},
        color: {default: "primaryColor"}
    },
}
</script>