// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// import 'firebase/firebase-messaging';
import {getMessaging, onMessage} from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBKIu9B6I1e4NR0KNBDBYIwxRFKfif9Mh4",
  authDomain: "shiftfish-2819b.firebaseapp.com",
  projectId: "shiftfish-2819b",
  storageBucket: "shiftfish-2819b.appspot.com",
  messagingSenderId: "847728050620",
  appId: "1:847728050620:web:a676b65811f0420d9fabb9",
  measurementId: "G-5EWR86N7TE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const firebaseMessaging = getMessaging(app);

export {app,firebaseMessaging,onMessage};
