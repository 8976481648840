
import router from '../../router/index';


export const state = {
    currentUser: localStorage.getItem('authUser') == "undefined" ? null : JSON.parse(localStorage.getItem('authUser'))
}

export const mutations = {
    SET_CURRENT_USER(state, newValue) {
        state.currentUser = newValue
        saveState('authUser', newValue)
    },
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
    currentUser(state) {
        return state.currentUser
    },
}

export const actions = {

    logIn({ dispatch, commit, getters }, { email, password , fcm_token }) {
        // if (getters.loggedIn) return dispatch('validate')
        //commit('loginRequest', { email });
        let loginData = {
            "email": email,
            "password": password,
            "fcm_token" : fcm_token
        }
        return new Promise((resolve, reject) => {

            this._vm.$axios.post('/login', loginData)
                .then((response) => {
                    if (response.data.status == true) {

                        const user = response.data.payload.user;
                        commit('SET_CURRENT_USER', user);

                        if (user.type == 'restaurant_owner' || user.type == 'manager') {
                            //  router.push('/');
                            window.location.href = "/";
                        } else if (user.type == 'team_member') {
                            //   router.push('/employee-profile');
                            if (user.employee.status == 'Inactive' || user.employee.status == 'Pending' || user.employee.status == 'Updated') {
                                window.location.href = "/employee-profile";
                            } else {
                                window.location.href = "/employee-home";
                            }
                        } else {
                            window.location.href = "/dashboard";
                        }
                    }
                    resolve(response);
                }).catch(error => {

                    console.log(error);
                    reject(error);
                    // dispatch('notification/error', error, { root: true });

                });

        })
    },

    // Logs out the current user.
    logOut({ commit }) {
        this._vm.$axios.post('/logout')
            .then((response) => {
                commit('SET_CURRENT_USER', null);
                // router.push('/login');
                location.href = '/login';
            });
    },

    me({ commit }) {
        if (state.currentUser != null) {
            this._vm.$axios.get('/me')
                .then(response => {
                    const user = response.data.payload.user;
                    commit('SET_CURRENT_USER', user);
                }).catch(error => {
                    commit('SET_CURRENT_USER', null);
                    location.href = '/login';
                });
        }

    },

    saveUser({commit},user) {
        if(user){
            commit('SET_CURRENT_USER', user);
            // router.push('/login');
        }
    },


}

// ===
// Private helpers
// ===

function saveState(key, state) {
    if (state == null) {
        window.localStorage.setItem(key, null)
    } else {
        window.localStorage.setItem(key, JSON.stringify(state))
    }
}
