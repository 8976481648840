const locationOBJ= {
    id:null,
    restaurant_id:null,
    name: null,
    start_day: null,
    lock_date:null,
    lock_date_formatted:null,
    areas:[
        {
            id: 1,
            area_id: null,
            area_roles: null,
            roles: [
              {
                role_id: null,
              },
            ],
          },
    ],
    
    area_roles:[],
    // {
    //     id:null,
    //     name:null,
    //     area_id:null,
    // },
    address:{
        id:null,
        city:null,
        country:null,
        state:null,
        address:null,
        zip:null,
    }

}
export const state = {
    location: JSON.parse(JSON.stringify(locationOBJ))
        // ...locationOBJ
}

export const actions = {
    getLocation({commit},{data}){
        // console.log('sdsdsaa');
        // this._vm.$axios.get('/me')
        // .then(response => {
        //     let data = response.data;
        // });
        commit('updateLocation',data)              
        
    },
    
    resetState({commit}) {
        
        commit('updateLocation',JSON.parse(JSON.stringify(locationOBJ)));
     }
    
}

export const mutations= {
    updateLocation(state, new_val) {
        state.location = new_val
    },
}

export const getters = {
    LocationGetter: state => state.location
  };




