<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { notificationMethods } from "@/state/helpers";
import { authMethods } from "@/state/helpers";
import {
  requestNotificationPermission,
  getFcmToken,
} from "@/plugins/messagingGetToken";
import { firebaseMessaging, onMessage } from "@/plugins/firebase.js";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    },
  },
  methods: {
    clearNotification: notificationMethods.clear,
    ...authMethods,

    requestNotification() {
      getFcmToken()
        .then((fcm_token) => {
          // console.log('fcm token',fcm_token);
          // this.setFcmToken(fcm_token);
        })
        .catch((e) => {
          console.log(e);
        });
        requestNotificationPermission();
    },

    spawnNotification(data) {
      let notify = null;
      if(data.notification){
         notify = data.notification;
      }else{
        notify = data.data;
      }
      const options = {
        body: notify.body,
      };
      const notification = new Notification(notify.title, options);

      notification.onclick = (event) => {
        event.preventDefault();
        // this.redirectToRelatedPath({type: data.action_type, property: {...data}});
        notification.close();
      };
    },
  },

  watch: {
    /**
     * Clear the alert message on route change
     */
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // clear alert on location change
      this.clearNotification();
    },
  },
  mounted() {
    this.me();
    this.requestNotification();
  },

  created() {
    onMessage(firebaseMessaging, (payload) => {
       console.log('Message received. ', payload);
      this.spawnNotification(payload);
    });
  },
};
</script>
