const userObj = {
    email: null,
    type: null,
    name: null,
    password:null,
    phone:null,
    avatar:null,
}
const restaurantObj = {
    name:null,
    owner_id:null,
    country:null,
    logo:null,
    user:{
        ...userObj
    }
}
export const state = {
        restaurant: {
            ...restaurantObj,
        },
    }
   
    export const actions = {
      

        resetState({commit}) {
            commit('updateRestaurant',restaurantObj);
         },
    }

  export const mutations= {
        updateRestaurant(state, new_val) {
            state.restaurant = new_val
        },
    }

    export const getters = {
        restaurantGetter: state => state.restaurant
      };




